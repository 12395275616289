<template>
	<div class="changepassword">
		<el-form
			:model="ruleForm"
			status-icon
			:rules="rules"
			ref="ruleForm"
			class="demo-ruleForm"
			style="width: 40%"
			label-position="left"
			label-width="120px"
		>
			<!-- <el-form-item
				label="用户名:"
				:rules="[{ required: false, message: '姓名不能为空' }]"
				prop="alias"
			>
				<el-input disabled :value="ruleForm.alias"></el-input>
			</el-form-item> -->
			<el-form-item label="旧密码:" prop="oldPassword">
				<el-input
					type="password"
					v-model="ruleForm.oldPassword"
					autocomplete="off"
					placeholder="请输入旧密码"
				></el-input>
				<p
					class="describe"
					style="color: #02a7f0; cursor: pointer; width: 70px"
					@click="$router.replace('/forgetpassword')"
				>
					忘记密码
				</p>
			</el-form-item>
			<el-form-item
				:rules="[
					{
						required: true,
						message: '请输入新密码',
						trigger: 'blur',
					},
					{
						min: 5,
						max: 25,
						message: '长度在 5 到 25个字符',
					},
					{
						pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{4,20}$/,
						message: '密码必须是由4-20位字母+数字组合',
					},
				]"
				label="新密码:"
				prop="password"
			>
				<el-input
					type="password"
					v-model="ruleForm.password"
					autocomplete="off"
					placeholder="请输入新密码"
				></el-input>
				<p class="describe">
					长度在 5 到 25个字符,密码必须是由4-20位字母+数字组合
				</p>
			</el-form-item>
			<el-form-item label="确认新密码:" prop="checkPass">
				<el-input
					type="password"
					v-model="ruleForm.checkPass"
					autocomplete="off"
					placeholder="请再次输入新密码"
				></el-input>
				<p class="describe">
					长度在 5 到 25个字符,密码必须是由4-20位字母+数字组合
				</p>
			</el-form-item>
			<el-form-item class="register-btn">
				<el-button
					style="width: 100%"
					type="primary"
					@click="submitForm('ruleForm')"
					:loading="loading"
					>保存</el-button
				>
				<el-button style="width: 100%" @click="$router.back()"
					>返回</el-button
				>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { modifyPassword } from '@/api/index.js';
export default {
	name: 'changepassword',
	data() {
		const validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入新密码'));
			} else if (value !== this.ruleForm.password) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		return {
			loading: false,
			ruleForm: {
				oldPassword: '',
				password: '',
				checkPass: '',
				userId: '',
			},
			rules: {
				checkPass: [
					{
						required: true,
						validator: validatePass2,
						trigger: 'blur',
					},
				],
			},
		};
	},
	methods: {
		submitForm(formName) {
			this.$refs[formName].validate(async (valid) => {
				if (valid) {
					this.ruleForm.userId = this.$store.state.userInfo.userId;
					const res = await modifyPassword(this.ruleForm);
					if (res.returncode == 0) {
						this.$message({
							message: '密码修改成功，请重新登录',
							type: 'success',
						});
						this.$router.replace('/login');
						return;
					}
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.changepassword {
	width: 100%;

	overflow: hidden;
	box-sizing: border-box;
	padding: 20px;
	padding-top: 100px;
	padding-left: 10%;
}
::v-deep .register-btn div {
	display: flex;
}
.describe {
	position: absolute;
	width: 200%;
	left: 103%;
	font-size: 12px;
	color: #999;
	top: 0px;
}
</style>
