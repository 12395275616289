var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"changepassword"},[_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",staticStyle:{"width":"40%"},attrs:{"model":_vm.ruleForm,"status-icon":"","rules":_vm.rules,"label-position":"left","label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"旧密码:","prop":"oldPassword"}},[_c('el-input',{attrs:{"type":"password","autocomplete":"off","placeholder":"请输入旧密码"},model:{value:(_vm.ruleForm.oldPassword),callback:function ($$v) {_vm.$set(_vm.ruleForm, "oldPassword", $$v)},expression:"ruleForm.oldPassword"}}),_c('p',{staticClass:"describe",staticStyle:{"color":"#02a7f0","cursor":"pointer","width":"70px"},on:{"click":function($event){return _vm.$router.replace('/forgetpassword')}}},[_vm._v(" 忘记密码 ")])],1),_c('el-form-item',{attrs:{"rules":[
				{
					required: true,
					message: '请输入新密码',
					trigger: 'blur',
				},
				{
					min: 5,
					max: 25,
					message: '长度在 5 到 25个字符',
				},
				{
					pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{4,20}$/,
					message: '密码必须是由4-20位字母+数字组合',
				} ],"label":"新密码:","prop":"password"}},[_c('el-input',{attrs:{"type":"password","autocomplete":"off","placeholder":"请输入新密码"},model:{value:(_vm.ruleForm.password),callback:function ($$v) {_vm.$set(_vm.ruleForm, "password", $$v)},expression:"ruleForm.password"}}),_c('p',{staticClass:"describe"},[_vm._v(" 长度在 5 到 25个字符,密码必须是由4-20位字母+数字组合 ")])],1),_c('el-form-item',{attrs:{"label":"确认新密码:","prop":"checkPass"}},[_c('el-input',{attrs:{"type":"password","autocomplete":"off","placeholder":"请再次输入新密码"},model:{value:(_vm.ruleForm.checkPass),callback:function ($$v) {_vm.$set(_vm.ruleForm, "checkPass", $$v)},expression:"ruleForm.checkPass"}}),_c('p',{staticClass:"describe"},[_vm._v(" 长度在 5 到 25个字符,密码必须是由4-20位字母+数字组合 ")])],1),_c('el-form-item',{staticClass:"register-btn"},[_c('el-button',{staticStyle:{"width":"100%"},attrs:{"type":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.submitForm('ruleForm')}}},[_vm._v("保存")]),_c('el-button',{staticStyle:{"width":"100%"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("返回")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }